import Vue from 'vue';
import VueRouter from 'vue-router';
import {RouteConfig} from 'vue-router/types/router';
import store from '@/store';
import {LoginApi} from '@/classes/api/login.api.class';
import {CheckLoginResponseDto} from '@/classes/dto/authenticator/response/CheckLogin.response.dto';
import {ConfigHandlerAPI} from '@/classes/api/config-handler.api.class';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {NotifierSocketApi} from '@/classes/api/notifier.socket.api';
import Login from '@/views/Login.vue';
import BookingCodeLandingPage from '@/views/bookingForm/BookingCodeLandingPage.vue';
import MissingPage from '@/views/MissingPage.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/courseRepairKit',
        name: 'Kursreparaturkit',
        component: () => import(/* webpackChunkName: "course-repair" */ '../views/private/CourseRepairKit.vue'),
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    },
    {
        path: '/demo',
        name: 'Demo der neuesten Funktionen in Vanilla',
        component: () => import(/* webpackChunkName: "demo" */ '../views/demoView.vue'),
    },
    {
        path: '/terminations',
        name: 'Kündigungen',
        component: () => import(/* webpackChunkName: "terminations" */ '../views/Terminations.vue'),
    },
    // {
    //   path: '/announcements',
    //   name: 'Ankündigungen',
    //   component: () => import('../views/Announcements.vue'),
    // },
    {
        path: '/schedule/:calendarDate?',
        name: 'Mein Stundenplan',
        component: () => import(/* webpackChunkName: "my-schedule" */ '../views/MySchedule.vue'),
    },
    {
        path: '/my-appointments/',
        name: 'Meine Termine',
        component: () => import(/* webpackChunkName: "my-appointments" */ '../views/MyAppointments.vue'),
    },
    {
        path: '/payoff/',
        name: 'Abrechnung',
        component: () => import(/* webpackChunkName: "payoff" */ '../views/Payoff.vue'),
    },
    {
        path: '/payoff-audit',
        name: 'Rechnungsprüfung',
        component: () => import(/* webpackChunkName: "payoff" */ '../views/PayoffAudit.vue'),
    },
    {
        path: '/courses',
        name: 'Kurse',
        component: () => import(/* webpackChunkName: "all-courses" */ '../views/AllCourses.vue'),
    },
    {
        path: '/courses/:courseId',
        name: 'Kurs',
        component: () => import(/* webpackChunkName: "course" */ '../views/Course.vue'),
    },
    {
        path: '/substitution-plan',
        name: 'Vertretungsplan',
        component: () => import(/* webpackChunkName: "substitution-plan" */ '../views/SubstitutionPlan.vue'),
    },
    {
        path: '/employees',
        name: 'Mitarbeitende',
        component: () => import(/* webpackChunkName: "employees" */ '../views/Employees.vue'),
    },
    {
        path: '/teachers',
        name: 'Lehrkräfte',
        component: () => import(/* webpackChunkName: "teachers" */ '../views/Teachers.vue'),
    },
    {
        path: '/tools/missingDocuments',
        name: 'Fehlende Dokumente',
        component: () => import(/* webpackChunkName: "missing-documents" */ '../views/MissingDocuments.vue'),
    },
    {
        path: '/teachers/:employeeId',
        name: 'Lehrkraft',
        // See employees/employeeId
        component: () => import(/* webpackChunkName: "employee" */ '../views/Employee.vue'),
    },
    {
        path: '/employees/:employeeId',
        name: 'Mitarbeitende:r',
        component: () => import(/* webpackChunkName: "employee" */ '../views/Employee.vue'),
    },
    {
        path: '/students',
        name: 'Alle Schüler',
        component: () => import(/* webpackChunkName: "students" */ '@/views/Students.vue'),
    },
    {
        path: '/students/:studentId',
        name: 'Schüler',
        component: () => import(/* webpackChunkName: "student" */ '@/views/Student.vue'),
    }, {
        path: '/parents',
        name: 'Alle Eltern',
        component: () => import(/* webpackChunkName: "parents" */ '@/views/Parents.vue'),
    },
    {
        path: '/parents/:parentId',
        name: 'Eltern',
        component: () => import(/* webpackChunkName: "parent" */ '../views/Parent.vue'),
    },
    {
        path: '/tools/bookInvoiceGenerator',
        name: 'Buchrechnungsgenerator',
        component: () => import(/* webpackChunkName: "book-invoices" */ '../views/tools/BookInvoiceGenerator.vue'),
    },
    {
        path: '/tools/sepaTools',
        name: 'SEPA XML',
        component: () => import(/* webpackChunkName: "sepa-xml" */ '../views/tools/SepaTools.vue'),
    },
    {
        path: '/tools/migration',
        name: 'Migration',
        component: () => import(/* webpackChunkName: "migration" */ '../views/tools/Migration.vue'),
    },
    {
        path: '/tools/migration/employees',
        name: 'Lehrkräfte und Mitarbeitende migrieren',
        component: () => import(/* webpackChunkName: "migration-employees" */ '../views/tools/migration/EmployeeMigration.vue'),
    },
    {
        path: '/tools/migration/institutions',
        name: 'Standorte migrieren',
        component: () => import(/* webpackChunkName: "migration-institutions" */ '../views/tools/migration/InstitutionMigration.vue'),
    },
    {
        path: '/tools/tags',
        name: 'Tag Manager',
        component: () => import(/* webpackChunkName: "tags" */ '../views/tools/TagManager.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/login',
        component: Login,
        meta: {
            guestOnly: true,
        },
    },
    {
        path: '/trackBookingState/:submissionId',
        name: 'Buchungsstatus',
        component: () => import(/* webpackChunkName: "track-booking-state" */ '../views/bookingForm/TrackBookingState.vue'),
        meta: {
            forAll: true,
            hideNavbar: true,
        },
    },
    {
        path: '/code',
        name: 'Anmeldung per Buchungscode',
        component: BookingCodeLandingPage,
        meta: {
            forAll: true,
            hideNavbar: true,
        },
    },
    {
        path: '/form/:shortId',
        name: 'Online-Anmeldung',
        component: () => import(/* webpackChunkName: "online-form" */ '../views/bookingForm/OnlineForm.vue'),
        meta: {
            forAll: true,
            hideNavbar: true,
        },
    },
    {
        path: '/stats',
        name: 'Statistiken und Analysen',
        component: () => import(/* webpackChunkName: "stats" */ '@/views/Stats.vue'),
    },
    {
        path: '/stats/teacher-termination-stats',
        name: 'Kündigungsrate der Lehrkräfte',
        component: () => import(/* webpackChunkName: "termination-stats" */ '@/views/stats/TeacherTerminationStats.vue'),
    },
    {
        path: '/stats/employee-acquisition-stats',
        name: 'Akquiseleistung der Mitarbeitenden',
        component: () => import(/* webpackChunkName: "employee-acquisition-stats" */ '@/views/stats/EmployeeAcquisitionStats.vue'),
    },
    {
        path: '/stats/employee-performance',
        name: 'Messung der Mitarbeitenden',
        component: () => import(/* webpackChunkName: "employee-performance" */ '@/views/stats/EmployeePerformanceStats.vue'),
    },
    {
        path: '/planning/handout-events',
        name: 'Austeilaktionen',
        component: () => import(/* webpackChunkName: "handout-events" */ '@/views/planning/HandoutEvents.vue'),
    },
    {
        path: '/planning/handout-events-feedback',
        name: 'Austeilaktionen - Feedback',
        component: () => import(/* webpackChunkName: "handout-events" */ '@/views/planning/HandoutEventsFeedback.vue'),
    },
    {
        path: '/planning/handout-events/:handoutEventId',
        name: 'Austeilaktion',
        component: () => import(/* webpackChunkName: "handout-event" */ '@/views/planning/HandoutEvent.vue'),
    },
    {
        path: '/planning/handout-events/advertiser-feedback/:handoutEventId',
        name: 'Feedback zur Austeilaktion - Werbende:r',
        component: () => import(/* webpackChunkName: "handout-event" */ '@/views/planning/HandoutEventAdvertiserFeedback.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '/planning/handout-events/institution-feedback/:handoutEventId',
        name: 'Feedback zur Austeilaktion - Standort',
        component: () => import(/* webpackChunkName: "handout-event" */ '@/views/planning/HandoutEventInstitutionFeedback.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '/planning/handout-overview',
        name: 'Terminübersicht für Austeilaktionen',
        component: () => import(/* webpackChunkName: "handout-overview" */ '@/views/planning/HandoutOverview.vue'),
    },
    {
        path: '/settings/import',
        name: 'Import',
        component: () => import(/* webpackChunkName: "import" */ '@/views/Import.vue'),
    },
    {
        path: '/settings/footer',
        name: 'Footer',
        component: () => import(/* webpackChunkName: "footer-config" */ '@/views/settings/FooterSettings.vue'),
    },
    {
        path: '/settings/course-table',
        name: 'Tabellarische Kursübersicht',
        component: () => import(/* webpackChunkName: "course-table" */ '@/views/settings/CourseTable.vue'),
    },
    {
        path: '/settings/referencing',
        name: 'Referenzierung',
        component: () => import(/* webpackChunkName: "referencing-custom-ids" */ '@/views/settings/Referencing.vue'),
    },
    {
        path: '/settings/standing-data',
        name: 'Stammdaten',
        component: () => import(/* webpackChunkName: "standing-data" */ '@/views/settings/StandingData.vue'),
    },
    {
        path: '/settings/permissions',
        component: () => import(/* webpackChunkName: "permissions" */ '@/views/settings/Permissions.vue'),
        children: [
            {
                path: '/',
                name: 'Berechtigungen',
                component: () => import(/* webpackChunkName: "permission-management" */ '@/views/settings/permissions/PermissionOverview.vue'),
            },
            {
                path: 'user/:id',
                name: 'Berechtigungen für Benutzer',
                component: () => import(/* webpackChunkName: "user-permission-management" */ '@/views/settings/permissions/UserPermissions.vue'),
            },
            {
                path: 'role/:id',
                name: 'Berechtigungen für Rolle',
                component: () => import(/* webpackChunkName: "role-permission-management" */ '@/views/settings/permissions/RolePermissions.vue'),
            },
        ],
    },
    {
        path: '/settings/auto-permissions',
        component: () => import(/* webpackChunkName: "auto-permissions" */ '@/views/settings/PermissionHooks.vue'),
        children: [
            {
                path: '/',
                name: 'Berechtigungsroutinen',
                component: () => import(/* webpackChunkName: "permission-hooks" */ '@/views/settings/permissionHooks/PermissionHookOverview.vue'),
            },
        ],
    },
    {
        path: '/settings/document-types',
        name: 'Dokumenttypen',
        component: () => import(/* webpackChunkName: "doc-types" */ '@/views/settings/DocumentTypes.vue'),
    },
    {
        path: '/settings/cron-jobs',
        name: 'Automatisierung',
        component: () => import(/* webpackChunkName: "automations" */ '@/views/settings/CronJobs.vue'),
    },
    {
        path: '/settings/api',
        name: 'APIs und Webhooks',
        component: () => import(/* webpackChunkName: "apis" */ '@/views/settings/APIsView.vue'),
    },
    {
        path: '/settings',
        name: 'Einstellungen',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    },
    {
        path: '/products',
        name: 'Logistik',
        component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
    },
    {
        path: '/products/:articleId',
        name: 'Produkt',
        component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
    },
    {
        path: '/templates/invoice',
        name: 'Rechnungsvorlagen',
        component: () => import(/* webpackChunkName: "invoice-templates" */ '../views/templates/InvoiceTemplates.vue'),
    },
    {
        path: '/templates/invoice/:id',
        name: 'Rechnungsvorlage',
        component: () => import(/* webpackChunkName: "invoice-template" */ '../views/templates/InvoiceTemplate.vue'),
    },
    {
        path: '/templates/contract-templates',
        name: 'Vergütungsvorlagen',
        component: () => import(/* webpackChunkName: "contracts" */ '../views/EmployeeContractTemplates.vue'),
    },
    {
        path: '/templates/mail',
        name: 'E-Mail Templates',
        component: () => import(/* webpackChunkName: "mail-templates" */ '../views/templates/MailTemplates.vue'),
    },
    {
        path: '/templates/mail/:templateId/:templateType',
        name: 'E-Mail Template',
        component: () => import(/* webpackChunkName: "mail-template" */ '../views/templates/MailEditor.vue'),
    },
    {
        path: '/templates/whatsapp/:id?',
        name: 'WhatsApp Templates',
        component: () => import(/* webpackChunkName: "whatsapp-templates" */ '../views/templates/WhatsAppTemplates.vue'),
    },
    {
        path: '/templates/handout',
        name: 'Anmeldungsvorlagen',
        component: () => import(/* webpackChunkName: "handout-templates" */ '@/views/templates/HandoutTemplates.vue'),
    },
    {
        path: '/institutions',
        name: 'Standorte',
        component: () => import(/* webpackChunkName: "institutions" */ '@/views/Institutions.vue'),
    },
    {
        path: '/institutions/:institutionId',
        name: 'Standort',
        component: () => import(/* webpackChunkName: "institution" */ '../views/Institution.vue'),
    },
    {
        path: '/coursematerials',
        name: 'Kursmaterialien',
        component: () => import (/* webpackChunkName: "course-materials" */ '../views/CourseMaterials.vue'),
    },
    {
        path: '/subjects',
        name: 'Fächer',
        component: () => import(/* webpackChunkName: "subjects" */ '../views/Subjects.vue'),
    },
    {
        path: '/vacation',
        name: 'Ferien und Pausen',
        component: () => import(/* webpackChunkName: "vacation" */ '../views/Vacation.vue'),
    },
    {
        path: '/taxonomies',
        name: 'Taxonomien',
        component: () => import(/* webpackChunkName: "vacation" */ '../views/TaxonomiesView.vue'),
    },
    {
        path: '/submissions',
        name: 'Anmeldungen',
        component: () => import(/* webpackChunkName: "submissions" */ '../views/bookingForm/SubmissionsView.vue'),
    },
    {
        path: '/booking-forms/:id/submissions',
        name: 'Anmeldungen für Formular',
        component: () => import(/* webpackChunkName: "form-submissions" */ '../views/bookingForm/SubmissionsForOnlineForm.vue'),
    },
    {
        path: '/booking',
        name: 'Vorlagen für Anmeldungen',
        component: () => import(/* webpackChunkName: "booking-form-templates" */ '../views/bookingForm/BookingFormTemplates.vue'),
    },
    {
        path: '/booking/:id',
        name: 'Anmeldungsformular',
        component: () => import(/* webpackChunkName: "booking-form-template" */ '../views/bookingForm/BookingFormTemplateView.vue'),
    },
    {
        path: '/booking/onlineFormBuilder/new/:templateId',
        name: 'Digitales Formular erzeugen',
        component: () => import(/* webpackChunkName: "new-digital-form" */ '../views/OnlineFormBuilder.vue'),
    },
    {
        path: '/booking/onlineFormBuilder/:templateId/:id',
        name: 'Digitales Formular bearbeiten',
        component: () => import(/* webpackChunkName: "edit-digital-form" */ '../views/OnlineFormBuilder.vue'),
    },
    {
        path: '/booking/onlineFormBuilderPreview/:formId',
        name: 'Online-Formular Vorschau',
        component: () => import(/* webpackChunkName: "form-preview" */ '../views/bookingForm/BookingFormBuilderPreview.vue'),
    },
    {
        path: '/booking/printedFormBuilder/new/:type/:templateId',
        name: 'Formular erstellen',
        component: () => import(/* webpackChunkName: "new-printed-form" */ '../views/bookingForm/PrintedFormBuilder.vue'),
    },
    {
        path: '/booking/printedFormBuilder/:type/:templateId/:id',
        name: 'Formular bearbeiten',
        component: () => import(/* webpackChunkName: "edit-printed-form" */ '../views/bookingForm/PrintedFormBuilder.vue'),
    },
    {
        path: '/trainings',
        name: 'Schulungen',
        component: () => import(/* webpackChunkName: "trainings" */ '../views/Trainings.vue'),
    },
    {
        path: '/signup/:id',
        name: 'Registrierung abschließen',
        component: () => import (/* webpackChunkName: "signup" */ '../views/Signup.vue'),
        meta: {
            guestOnly: true,
        },
    },
    {
        path: '/handout-event-confirmation/:token',
        name: 'Terminbestätigung für Austeilaktion',
        component: () => import (/* webpackChunkName: "handout-event-confirmation" */ '../views/HandoutEventConfirmation.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '/imprint',
        name: 'Impressum',
        component: () => import(/* webpackChunkName: "imprint" */ '../views/legal/Imprint.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '/compliance',
        name: 'Datenschutz',
        component: () => import(/* webpackChunkName: "compliance" */ '../views/legal/Compliance.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '/jobAds',
        name: 'Stellenanzeigen',
        component: () => import (/* webpackChunkName: "job-ads" */ '../views/JobAds.vue'),
    },
    {
        path: '/jobAds/:jobAdId',
        name: 'Stellenanzeige',
        component: () => import (/* webpackChunkName: "job-ad" */ '../views/JobAds.vue'),
    },
    {
        path: '/applicants',
        name: 'Bewerber:innen',
        component: () => import (/* webpackChunkName: "applicants" */ '../views/Applicants.vue'),
    },
    {
        path: '/applicants/:employeeId',
        name: 'Bewerber:in',
        // See employees/employeeId
        component: () => import (/* webpackChunkName: "employee" */ '../views/Employee.vue'),
    },
    {
        path: '/acquisitionLists',
        name: 'Akquiselisten',
        component: () => import (/* webpackChunkName: "acquisition-lists" */ '../views/AcquisitionLists.vue'),
    },
    {
        path: '/acquisitionLists/:acquisitionListId',
        name: 'Akquiseliste',
        component: () => import(/* webpackChunkName: "acquisition-list" */ '@/views/AcquisitionList.vue'),
    },
    {
        path: '/acquisitionLists/:acquisitionListId/:institutionId',
        name: 'Akquisestandort',
        component: () => import(/* webpackChunkName: "acquisition-list-institution" */ '@/views/AcquisitionListItem.vue'),
    },
    {
        path: '/acquisitionListsOverview',
        name: 'Akquiseübersicht',
        component: () => import(/* webpackChunkName: "acquisition-overview" */ '@/views/AcquisitionListsOverview.vue'),
    },
    {
        path: '/acquisitionListsOverview/progress',
        name: 'Akquiseübersicht- In Arbeit',
        component: () => import(/* webpackChunkName: "acquisition-in-progress" */ '@/views/acquisitionListsOverview/AcquisitionListItemsInProgress.vue'),
    },
    {
        path: '/acquisitionListsOverview/committed',
        name: 'Akquiseübersicht- Zugesagt',
        component: () => import(/* webpackChunkName: "acquisition-committed" */ '@/views/acquisitionListsOverview/AcquisitionListItemsCommitted.vue'),
    },
    {
        path: '/acquisitionListsOverview/closed',
        name: 'Akquiseübersicht- Abgesagt',
        component: () => import(/* webpackChunkName: "acquisition-closed" */ '@/views/acquisitionListsOverview/AcquisitionListItemsClosed.vue'),
    },
    {
        path: '/qualityManagement/teachers',
        name: 'Qualitätsmanagement - Lehrkräfte',
        component: () => import('@/views/qualityManagement/QualityManagementTeachersView.vue'),
    },
    {
        path: '/qualityManagement/institutions',
        name: 'Qualitätsmanagement - Standorte',
        component: () => import('@/views/qualityManagement/QualityManagementInstitutionsView.vue'),
    },
    {
        path: '/substitution-inquiries-table',
        name: 'Vertretungsanfragen',
        component: () => import('@/views/SubstitutionInquiriesTableView.vue'),
    },
    {
        path: '/todos/:todoId?',
        name: 'ToDos',
        component: () => import('@/views/ToDos.vue'),
    },
    {
        path: '/substitution-inquiries/:employeeId?',
        name: 'Mögliche Vertretungen',
        component: () => import('@/views/SubstitutionInquiriesView.vue'),
        meta: {
            forAll: true,
        },
    },
    {
        path: '*',
        name: '404',
        component: () => MissingPage,
        meta: {
            forAll: true,
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to, from, next) => {
    let triggerLoading = true;
    setTimeout(() => {
        if (triggerLoading) {
            store.commit('setLoadingState', true);
            triggerLoading = false;
        }
    }, 200);
    if (to.meta?.guestOnly && store.state.axios) {
        if (store.state.routeCache) {
            router.push(store.state.routeCache.path).catch();
            store.commit('setRouteCache', null);
        } else {
            router.push('/').catch();
        }
    } else if (!to.meta || !to.meta.guestOnly && !store.state.axios) {
        const forAll = !!to.meta?.forAll;

        try {
            let resp: CheckLoginResponseDto | undefined;
            try {
                resp = await LoginApi.checkLogin();
            } catch (err) {
                if (!forAll) {
                    store.commit('setRouteCache', to);
                }
            }
            if (resp) {
                store.commit('login');
                store.commit('user', resp.meta);
                store.commit('setUserType', resp.loginType);
                store.commit('setOriginUserId', resp.originUserId);
                store.commit('setLoginUserId', resp.loginUserId);
                await LoginApi.setCrud();
                const cam = store.state.crudAccessManager;
                if (cam.canAccessType(CrudEntityTypes.CONFIG, CrudActionTypes.READ)) {
                    const dhlApiConfig = await ConfigHandlerAPI.getDhlApiConfig();
                    store.commit('setDhlApiConfig', dhlApiConfig);
                } else {
                    store.commit('setDhlApiConfig', {
                        useTrackingApi: false,
                        useDhlApi: false,
                        useRetoureApi: false,
                    });
                }
                store.commit('setAppReady', true);
                await NotifierSocketApi.connect();
                next();
            } else {
                if (forAll) {
                    next();
                } else {
                    next('/login');
                }
            }
        } catch (error) {
            if (forAll) {
                next();
            } else {
                next('/login');
            }
        }
    } else {
        store.commit('setTitle', to.name);
        store.commit('hideActions');
        if (store.state.axios) {
            await LoginApi.setCrud();
            store.commit('setAppReady', true);
        }
        next();
    }
    triggerLoading = false;
    store.commit('setLoadingState', false);
});

export default router;
